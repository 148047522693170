var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"new-user-info"},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Title:","label-for":"title"}},[_c('ValidationProvider',{ref:"title",attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var title = ref.title;
return [_vm._v(" "+_vm._s(title)+" "),_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Title"},model:{value:(_vm.callStatus.title),callback:function ($$v) {_vm.$set(_vm.callStatus, "title", $$v)},expression:"callStatus.title"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Reserve Period:","label-for":"title"}},[_c('ValidationProvider',{attrs:{"name":"Days","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"number","placeholder":"Ex: 1 "},model:{value:(_vm.callStatus.days),callback:function ($$v) {_vm.$set(_vm.callStatus, "days", $$v)},expression:"callStatus.days"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Rerturn Period:","label-for":"title"}},[_c('ValidationProvider',{attrs:{"name":"Days","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"number","placeholder":"Ex: 1 "},model:{value:(_vm.callStatus.return),callback:function ($$v) {_vm.$set(_vm.callStatus, "return", $$v)},expression:"callStatus.return"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label-for":"exampleInputcolor","label":"Status color"}},[_c('ValidationProvider',{ref:"color",attrs:{"name":"color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"color","id":"exampleInputcolor"},model:{value:(_vm.callStatus.color),callback:function ($$v) {_vm.$set(_vm.callStatus, "color", $$v)},expression:"callStatus.color"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Hold Period:","label-for":"title"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Ex: 1 "},model:{value:(_vm.callStatus.hold),callback:function ($$v) {_vm.$set(_vm.callStatus, "hold", $$v)},expression:"callStatus.hold"}})],1)],1),_c('div',{staticClass:"d-flex  mt-4 align-items-center justify-content-between"},[_c('b-form-checkbox',{model:{value:(_vm.callStatus.is_active),callback:function ($$v) {_vm.$set(_vm.callStatus, "is_active", $$v)},expression:"callStatus.is_active"}},[_vm._v("Is Active")]),(_vm.edit)?_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Edit callStatus")]):_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Add New callStatus")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }